@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~nvd3/build/nv.d3.css';

@import 'ngx-perfect-scrollbar';
@import 'react-full-screen';
@import 'css-animator';
@import 'datatables';
@import 'google-map';
@import 'react-bootstrap/react-bootstrap';

.dropzone {
    .dz-progress {
        display: none;
    }
}
